import styled from "styled-components"

const Button = styled.button`
  text-transform: uppercase;
  padding: 10px 20px;
  background: ${props => props.theme.colors.main};
  color: #fff;
  font-family: "Baloo 2", cursive;
  border: none;
  border-radius: 8px;
  font-size: 24px;
  cursor: pointer;

  &:hover {
    background-color: #f24940;
  }
`

export default Button
