import styled from "styled-components"

const ZebraImageWrapper = styled.div`
  max-width: 275px;
  margin: 20px auto 0px;
  display: none;

  @media (${props => props.theme.mediaQueries.largeDesktop}) {
    display: block;
  }
`

export default ZebraImageWrapper
