import styled from "styled-components"

const Copyright = styled.div`
  text-align: center;
  color: #fff;
  font-size: 14px;
  margin-top: 30px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-top: 80px;
  }
`

export default Copyright
