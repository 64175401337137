import styled from "styled-components"

const SuccessText = styled.p`
  font-size: 22px;
  text-align: center;
  color: #2a9d8f;
  margin-top: 16px;
`

export default SuccessText
