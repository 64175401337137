import styled from "styled-components"

const Container = styled.div`
  display: flex;
  height: 65px;
  max-width: 1250px;
  margin: 0px auto;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding: 0px 25px;
    height: 100px;
  }
`

export default Container
