import React from "react"

//React component import
import ContactCard from "./ContactCard"
import ContactForm from "./ContactForm"

//Styled components imports
import Wrapper from "./Wrapper"

const Order = () => {
  return (
    <Wrapper id="order">
      {/* <ContactCard /> */}
      <ContactForm />
    </Wrapper>
  )
}

export default Order
