import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

//Styled components imports
import Wrapper from "./Wrapper"
import Title from "../../shared-styled-components/Title"
import Container from "./Container"
import Text from "../../shared-styled-components/Text"
import BoldText from "../../shared-styled-components/BoldText"
import ImageWrapper from "./ImageWrapper"
import HighlightedText from "./HighlightedText"
import TextWrapper from "./TextWrapper"

const AboutMe = () => {
  const data = useStaticQuery(graphql`
    query BearImage {
      image: file(relativePath: { eq: "polar-bear.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper id="about-me">
      {/* <Title>O mně</Title> */}
      <Container>
        <TextWrapper>
          <Text>
            {/* Jmenuji se <BoldText>Radka Brožová</BoldText>, narodila jsem se v
            Olomouci, žiji v Praze a většinu básniček jsem napsala v malé
            vesničce Oslov v Jižních Čechách. Nejdřív jsem je vlastně skládala
            pro moje dvě děti, ale chyběly jim k tomu obrázky a protože já
            nejsem mistr malíř, došlo na hledání profesionála. No a když tyhle
            veselé a rytmicky přesné verše doplnila svými krásnými obrázky jedna
            z předních českých ilustrátorek{" "}
            <BoldText>Zdeňka Študlarová</BoldText>, vzniklo, podle mého
            skromného názoru, to nejlepší dílo české poezie pro děti (mimo
            Hrubína, Žáčka, Kožíška a všech těch dalších úžasných spisovatelů,
            samozřejmě) :-) A teď vážně... na trhu je velká spousta knížek pro
            děti, ale tahle je (alespoň podle reakcí těch, kdo ji už mají) jedna
            z nejkrásnějších. A já doufám, že i Vaše děti z ní budou stejně
            nadšené jako ty moje. */}
            Knížka má velikost cca A4, 70 stran a krásné obrázky od přední české
            ilustrátorky Zdeňky Študlarové. Básničky i hádanky zahrnují většinu
            dětmi oblíbených témat, od zvířátek po pohádkové postavy a několik
            veršovánek pro bystré hlavičky (Zeměkoule, dopravní značky,
            světadíly...). A nakonec také pár veršů na dobrou noc. Všechny
            básničky jsem psala pro své děti a do knížky jsem dala jen ty
            nejoblíbenější. I vaše děti z nich budou nadšené.
          </Text>
          {/* <HighlightedText> doufám, že se bude líbit i Vám!</HighlightedText> */}
        </TextWrapper>
        <ImageWrapper>
          <Img fluid={data.image.childImageSharp.fluid} alt="Polární edvěd" />
        </ImageWrapper>
      </Container>
    </Wrapper>
  )
}

export default AboutMe
