import styled from "styled-components"

const Title = styled.h2`
  font-weight: 800;
  font-size: 36px;
  margin-bottom: 8px;
  text-align: center;
  padding-top: 4px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    margin-bottom: 24px;
    text-align: left;
  }
`

export default Title
