import styled from "styled-components"

const Text = styled.p`
  position: relative;
  z-index: 2;
  text-align: center;
  color: #4c4c4c;
  font-weight: 800;
  font-size: 22px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 24px;
    text-align: left;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 36px;
  }
`

export default Text
