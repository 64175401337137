import styled from "styled-components"

const Links = styled.div`
  text-align: center;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: left;
    position: absolute;
    top: 42px;
    left: 200px;
  }
`

export default Links
