import styled from "styled-components"

const DesktopImagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 32px;
  margin-top: 20px;
`

export default DesktopImagesWrapper
