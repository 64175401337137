import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { SRLWrapper } from "simple-react-lightbox"

//Styled components imports
import Wrapper from "./Wrapper"
import Title from "../../shared-styled-components/Title"
import Text from "../../shared-styled-components/Text"
import DesktopImagesWrapper from "./DesktopImagesWrapper"
import MobileImagesWrapper from "./MobileImagesWrapper"
import Media from "./Media"
import ImageWrapper from "./ImageWrapper"
import Image from "./Image"

const AboutBook = () => {
  const data = useStaticQuery(graphql`
    query Images {
      images: allFile(
        filter: { relativeDirectory: { eq: "about-book" } }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          childImageSharp {
            fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          id
        }
      }
    }
  `)

  return (
    <Wrapper id="about-book">
      <Title>O knížce</Title>
      <Text>
        Projděte se po stránkách plných krásných obrázků, veselých básniček a
        veršovaných hádanek. Zatoulejte se do světa, kde žraloci zuby cení, kde
        se vodník s vílou žení, kde beruška tečku hledá, kde je skřítek
        neposeda. Kde je všeho všudy k mání, hrstka rýmů pro uspání. Hrady se tu
        z kostek staví, praví hříbci z mechu zdraví, a jak jedno rčení praví:
        <em> „Dobrá kniha nerezaví“</em>.
      </Text>
      <SRLWrapper
        options={{
          buttons: {
            iconColor: "#F46E67",
            showDownloadButton: false,
          },
        }}
      >
        <Media lessThan="tablet">
          <MobileImagesWrapper>
            {data.images.nodes.map(image => {
              return (
                <ImageWrapper key={image.id}>
                  <Image
                    style={{ cursor: "pointer" }}
                    fluid={image.childImageSharp.fluid}
                    alt="Ukázka z knížky"
                  />
                </ImageWrapper>
              )
            })}
          </MobileImagesWrapper>
        </Media>
        <Media greaterThanOrEqual="tablet">
          <DesktopImagesWrapper>
            {data.images.nodes.map(image => {
              return (
                <Image
                  key={image.id}
                  style={{ cursor: "pointer" }}
                  fluid={image.childImageSharp.fluid}
                  alt="Ukázka z knížky"
                />
              )
            })}
          </DesktopImagesWrapper>
        </Media>
      </SRLWrapper>
    </Wrapper>
  )
}

export default AboutBook
