import styled from "styled-components"

const Input = styled.input`
  border-radius: 6px;
  border: 1px white solid;
  height: 40px;
  display: block;
  width: 100%;
`

export default Input
