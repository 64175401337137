import styled from "styled-components"

const Wrapper = styled.div`
  max-width: 720px;
  background-color: #e8d8b0;
  margin: auto;
  padding: 28px;
  border-radius: 25px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding: 100px;
  }
`

export default Wrapper
