import styled from "styled-components"

const ContactText = styled.a`
  display: inline-block;
  margin-left: 8px;
  color: #fff;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

export default ContactText
