import React from "react"

import { Helmet } from "react-helmet"

//React components imports
import Layout from "../components/Layout"
import Introduction from "../components/Introduction"
import AboutBook from "../components/AboutBook"
import AboutMe from "../components/AboutMe"
import Order from "../components/Order"

const HomePage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Radka Brožová</title>
        <meta
          name="description"
          content="Radka Brožová, autorka dětské knížky Pro děti a pro radost"
        />
      </Helmet>
      <Introduction />
      <AboutBook />
      <AboutMe />
      <Order />
    </Layout>
  )
}

export default HomePage
