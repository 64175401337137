import styled from "styled-components"
import { Envelope } from "@styled-icons/boxicons-solid/Envelope"

const ContactIcon = styled(Envelope)`
  width: 42px;
  height: 42px;
  color: #fff;
  cursor: pointer;

  &:hover {
    color: #feedec;
  }
`

export default ContactIcon
