import styled from "styled-components"

const Contact = styled.div`
  position: absolute;
  right: 59px;
  top: 60px;
  color: #fff;
`

export default Contact
