import styled from "styled-components"

const Text = styled.p`
  font-size: ${props => (props.small ? "16px" : "19px")};
  margin-bottom: ${props => (props.marginBottom ? props.marginBottom : "0px")};

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: ${props => (props.small ? "18px" : "28px")};
  }
`

export default Text
