import styled from "styled-components"

const NavLinks = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 290px;
`

export default NavLinks
