import styled from "styled-components"

const Email = styled.a`
  font-size: 16px;
  font-weight: 600;
  color: ${props => props.theme.colors.text};

  &:hover {
    color: ${props => props.theme.colors.main};
  }

  @media (min-width: 360px) {
    font-size: 19px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 38px;
  }
`
export default Email
