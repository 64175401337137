import styled, { css } from "styled-components"

const Link = styled.a`
  font-size: 32px;
  margin-bottom: 42px;
  display: inline-block;
  color: ${props => props.theme.colors.text};
  cursor: pointer;
  ${props =>
    props.order &&
    css`
      padding: 12px 20px;
      background-color: ${props => props.theme.colors.main};
      border-radius: 10px;
      color: #fff;
    `}

  &:hover {
    color: ${props => (props.order ? null : props.theme.colors.main)};
    background-color: ${props => (props.order ? "#F24940" : null)};
  }
`

export default Link
