import styled from "styled-components"

const Main = styled.main`
  max-width: 1250px;
  width: 100%;
  margin: auto;

  padding: 0px 20px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding: 0px 25px;
  }
`

export default Main
