import styled from "styled-components"

const ImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 15px;
  width: 42px;

  @media (${props => props.theme.mediaQueries.mobile}) {
    width: 52px;
  }

  @media (min-width: 380px) {
    width: 65px;
  }

  @media (min-width: 520px) {
    width: 85px;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    width: 120px;
  }
`

export default ImageWrapper
