import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

//Styled components imports
import Wrapper from "./Wrapper"
import Nav from "./Nav"
import NavLinks from "./NavLinks"
import NavLink from "./NavLink"
import Link from "./Link"
import Contact from "./Contact"
import ContactIcon from "./ContactIcon"
import ContactText from "./ContactText"

const MenuOverlay = ({ setMenuOpen }) => {
  const overlayVariants = {
    initial: {
      x: "100%",
      transition: {
        type: "tween",
        duration: 0.3,
      },
    },
    open: {
      x: 0,
      transition: {
        type: "tween",
        duration: 0.3,
      },
    },
  }

  return (
    <Wrapper
      key="menu"
      variants={overlayVariants}
      initial="initial"
      animate="open"
      exit="initial"
    >
      <Nav>
        <NavLinks>
          <NavLink>
            <Link
              onClick={() => {
                setMenuOpen(false)
                scrollTo("#about-book")
              }}
              aria-label="O knížce"
            >
              O knížce
            </Link>
          </NavLink>
          {/* <NavLink>
            <Link
              onClick={() => {
                setMenuOpen(false)
                scrollTo("#about-me")
              }}
              aria-label="O mně"
            >
              O mně
            </Link>
          </NavLink> */}
          <NavLink>
            <Link
              onClick={() => {
                setMenuOpen(false)
                scrollTo("#order")
              }}
              order
              aria-label="Objednávka"
            >
              Objednávka
            </Link>
          </NavLink>
        </NavLinks>
      </Nav>
      <Contact>
        <a
          href="mailto:prodetiaproradost@seznam.cz"
          aria-label="Kontakt - e-mail"
        >
          <ContactIcon />
        </a>
        <ContactText
          href="mailto:prodetiaproradost@seznam.cz"
          aria-label="Kontakt - e-mail"
        >
          Neváhejte mě kontaktovat
        </ContactText>
      </Contact>
    </Wrapper>
  )
}

export default MenuOverlay
