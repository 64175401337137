import styled from "styled-components"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 32px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 2fr 1fr;
    grid-column-gap: 105px;
  }
`

export default Container
