import React from "react"
import { ThemeProvider } from "styled-components"
import GlobalStyle from "../../utils/styled-components/globalStyles"
import { theme } from "../../utils/styled-components/theme"

//React components imports
import Footer from "../Footer"
import Wrapper from "./Wrapper"
import Header from "../Header"

//Styled components imports
import Main from "./Main"

const Layout = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <Wrapper>
        <Header />
        <Main>{children}</Main>
        <Footer />
      </Wrapper>
    </ThemeProvider>
  )
}

export default Layout
