import styled from "styled-components"

const TextArea = styled.textarea`
  display: block;
  width: 100%;
  border-radius: 6px;
  border: 1px white solid;
`

export default TextArea
