import styled from "styled-components"

const Container = styled.div`
  position: relative;
  max-width: 1250px;
  margin: auto;
  padding: 60px 15px 0px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    padding: 60px 25px 0px;
  }
`

export default Container
