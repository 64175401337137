import styled from "styled-components"

const MainVerse = styled.span`
  display: block;
  font-weight: 800;
  margin-top: 16px;
  font-size: 32px;
  line-height: 32px;
  text-transform: uppercase;
  color: #00a2d6;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 36px;
    line-height: 36px;
  }
  @media (${props => props.theme.mediaQueries.desktop}) {
    font-size: 48px;
    line-height: 48px;
  }
`

export default MainVerse
