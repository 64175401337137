import React from "react"
import scrollTo from "gatsby-plugin-smoothscroll"

//Styled components imports
import Wrapper from "./Wrapper"
import NavLinks from "./NavLinks"
import NavLink from "./NavLink"
import Link from "./Link"

const DesktopNav = () => {
  return (
    <Wrapper>
      <NavLinks>
        <NavLink>
          <Link
            onClick={() => {
              scrollTo("#about-book")
            }}
            aria-label="O knížce"
          >
            O knížce
          </Link>
        </NavLink>
        {/* <NavLink>
          <Link
            onClick={() => {
              scrollTo("#about-me")
            }}
            aria-label="O mně"
          >
            O mně
          </Link>
        </NavLink> */}
        <NavLink>
          <Link
            order="true"
            onClick={() => {
              scrollTo("#order")
            }}
            aria-label="Objednávka"
          >
            Objednávka
          </Link>
        </NavLink>
      </NavLinks>
    </Wrapper>
  )
}

export default DesktopNav
