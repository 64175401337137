import React from "react"

//React component import
import DesktopNav from "./DesktopNav"
import MobileNav from "./MobileNav"

//Styled components imports
import LogoLink from "./LogoLink"
import Logo from "./Logo"
import Wrapper from "./Wrapper"
import Container from "./Container"
import Media from "./Media"

const Header = () => {
  return (
    <Wrapper>
      <Container>
        <LogoLink to="/">
          <Logo>Radka Brožová</Logo>
        </LogoLink>
        <Media lessThan="tablet">
          <MobileNav />
        </Media>
        <Media greaterThanOrEqual="tablet">
          <DesktopNav />
        </Media>
      </Container>
    </Wrapper>
  )
}

export default Header
