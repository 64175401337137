import styled from "styled-components"

const MobileImagesWrapper = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  margin-right: -15px;
  margin-top: 32px;
`

export default MobileImagesWrapper
