import styled from "styled-components"

const ContactText = styled.a`
  font-size: 28px;
  color: ${props => props.theme.colors.text};

  &:hover {
    text-decoration: underline;
  }
`

export default ContactText
