import React, { useState, useEffect } from "react"
import { motion } from "framer-motion"
import { AnimatePresence } from "framer-motion"

//React component import
import MenuOverlay from "./MenuOverlay"

//Styled components imports
import Wrapper from "./Wrapper"
import MenuIconWrapper from "./MenuIconWrapper"

const MobileNav = () => {
  const [menuOpen, setMenuOpen] = useState(false)

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "visible"
    }
  }, [menuOpen])

  const lineOneVariants = {
    hamburger: {
      rotate: 0,
      y: 0,
    },
    cross: { rotate: 45, y: 9 },
  }

  const lineTwoVariants = {
    hamburger: {
      opacity: 1,
      transition: {
        duration: 0.2,
      },
    },
    cross: {
      opacity: 0,
      transition: {
        duration: 0.1,
      },
    },
  }

  const lineThreeVariants = {
    hamburger: {
      rotate: 0,
      y: 0,
    },
    cross: {
      rotate: -45,
      y: -9,
    },
  }

  return (
    <Wrapper>
      <AnimatePresence>
        {menuOpen && (
          <MenuOverlay menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
        )}
      </AnimatePresence>
      <MenuIconWrapper
        onClick={() => {
          setMenuOpen(!menuOpen)
        }}
      >
        <svg
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0)">
            <motion.path
              d="M26 2.1001H0V5.8001H26V2.1001Z"
              fill="#4C4C4C"
              variants={lineOneVariants}
              initial="hamburger"
              animate={menuOpen ? "cross" : "hamburger"}
            />
            <motion.path
              d="M26 11.3999H0V15.0999H26V11.3999Z"
              fill="#4C4C4C"
              variants={lineTwoVariants}
              initial="hamburger"
              animate={menuOpen ? "cross" : "hamburger"}
            />
            <motion.path
              d="M26 20.7H0V24.4H26V20.7Z"
              fill="#4C4C4C"
              variants={lineThreeVariants}
              initial="hamburger"
              animate={menuOpen ? "cross" : "hamburger"}
            />
          </g>
        </svg>
      </MenuIconWrapper>
    </Wrapper>
  )
}

export default MobileNav
