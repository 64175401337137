import styled from "styled-components"

const ImageWrapper = styled.div`
  width: 60%;
  margin: 0px auto 24px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    width: 100%;
    margin: 0px;
  }
`

export default ImageWrapper
