import styled from "styled-components"
import Img from "gatsby-image"

const Image = styled(Img)`
  transition: all 0.1s ease-in;
  &:hover {
    opacity: 0.93;
    transform: scale(1.01);
  }
`

export default Image
