import styled from "styled-components"
import { Envelope } from "@styled-icons/boxicons-solid/Envelope"

const ContactIcon = styled(Envelope)`
  display: block;
  margin: 0px auto 16px;
  width: 42px;
  height: 42px;
  color: ${props => props.theme.colors.text};
  cursor: pointer;

  &:hover {
    color: #707070;
  }
`

export default ContactIcon
