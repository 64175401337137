import styled from "styled-components"

const Select = styled.select`
  font-size: 18px;
  padding: 4px 12px;
  border-radius: 6px;
  border: 1px white solid;
`

export default Select
