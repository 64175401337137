import styled from "styled-components"
import { Link as GatsbyLink } from "gatsby"

const Link = styled(GatsbyLink)`
  display: inline-block;
  color: #fff;

  &:hover {
    text-decoration: underline;
  }

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 18px;
  }
`

export default Link
