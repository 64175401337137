import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

//Styled components imports
import Wrapper from "./Wrapper"
import ImageWrapper from "./ImageWrapper"
import Text from "./Text"
import MainVerse from "./MainVerse"
import Container from "./Container"
import ZebraImageWrapper from "./ZebraImageWrapper"

const Introduction = () => {
  const data = useStaticQuery(graphql`
    query IntroductionImages {
      frontImage: file(relativePath: { eq: "PRO_DETI_TITUL.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      zebraImage: file(relativePath: { eq: "zebra-with-trumpet.png" }) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper>
      <ImageWrapper>
        <Img
          fluid={data.frontImage.childImageSharp.fluid}
          alt="Titulní strana"
        />
      </ImageWrapper>
      <Container>
        <Text>
          Odemykám klíčem bránu,
          <br></br>projdi dál a přejdi most,
          <br></br> jenom krátký, do pohádky, <br></br>
          <MainVerse>pro děti a pro radost.</MainVerse>
        </Text>
        <ZebraImageWrapper>
          <Img
            fluid={data.zebraImage.childImageSharp.fluid}
            alt="Zebra s trumpetou"
          />
        </ZebraImageWrapper>
      </Container>
    </Wrapper>
  )
}

export default Introduction
