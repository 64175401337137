import styled from "styled-components"

const Wrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  margin-bottom: 32px;
  margin-top: 8px;
  grid-row-gap: 32px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    grid-column-gap: 60px;
  }

  @media (${props => props.theme.mediaQueries.desktop}) {
    grid-column-gap: 80px;
  }
`

export default Wrapper
