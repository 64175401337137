import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import background from "../../images/footer-background.svg"

//Styled components imports
import Container from "./Container"
import Media from "./Media"
import ImageWrapper from "./ImageWrapper"
import Links from "./Links"
import Link from "./Link"
import Contact from "./Contact"
import ContactText from "./ContactText"
import ContactIcon from "./ContactIcon"
import Copyright from "./Copyright"
import Wrapper from "./Wrapper"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query MouseImage {
      image: file(relativePath: { eq: "mouse-and-cheese.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <Wrapper background={background}>
      <Container>
        <ImageWrapper>
          <Img fluid={data.image.childImageSharp.fluid} alt="Myš a sýr" />
        </ImageWrapper>
        {/* <Links>
          <Link to="/">Zpracování osobních údajů</Link> <br></br>
          <Link to="/">Obchodní podmínky</Link>
        </Links> */}
        <Media greaterThanOrEqual="tablet">
          <Contact>
            <a
              href="mailto:prodetiaproradost@seznam.cz"
              aria-label="Kontakt - e-mail"
            >
              <ContactIcon />
            </a>
            <ContactText
              href="mailto:prodetiaproradost@seznam.cz"
              aria-label="Kontakt - e-mail"
            >
              Neváhejte mě kontaktovat
            </ContactText>
          </Contact>
        </Media>
        <Copyright>{new Date().getFullYear()} &copy; Radka Brožová</Copyright>
      </Container>
    </Wrapper>
  )
}

export default Footer
