import styled from "styled-components"

const HighlightedText = styled.p`
  font-weight: 500;
  font-size: 28px;
  text-align: center;
  margin-top: 8px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    text-align: right;
    margin-top: 16px;
  }
`

export default HighlightedText
