import styled from "styled-components"

const PriceText = styled.span`
  color: ${props => props.theme.colors.main};
  font-weight: 600;
  font-size: 32px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    font-size: 42px;
  }
`
export default PriceText
