import styled from "styled-components"

const Wrapper = styled.footer`
  background-image: url(${props => props.background});
  background-size: cover;

  height: 130px;

  @media (${props => props.theme.mediaQueries.tablet}) {
    height: 177px;
  }
`

export default Wrapper
