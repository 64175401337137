import React, { useState } from "react"

import { useForm } from "react-hook-form"
import axios from "axios"

//Styled components imports
import Wrapper from "./Wrapper"
import Form from "./Form"
import Title from "./Title"
import Input from "./Input"
import Select from "./Select"
import TextArea from "./TextArea"
import Label from "./Label"
import ErrorContainer from "./ErrorContainer"
import ErrorText from "./ErrorText"
import ButtonWrapper from "./ButtonWrapper"
import Button from "./Button"
import Text from "./Text"
import NoteText from "./NoteText"
import BoldText from "../../../shared-styled-components/BoldText"
import PriceText from "./PriceText"
import SuccessText from "./SuccessText"

const ContactForm = () => {
  const { register, handleSubmit, errors, getValues, reset } = useForm()
  const [deliveryPrice, setDeliveryPrice] = useState("45 Kč")
  const [submitted, setSubmitted] = useState(false)

  const getDeliveryPrice = () => {
    const values = getValues()

    if (parseInt(values.amount) >= 3) {
      setDeliveryPrice("0 Kč")
    } else {
      setDeliveryPrice("45 Kč")
    }
  }

  const onSubmit = data => {
    axios({
      method: "post",
      url: "https://formspree.io/f/mleonwrg",
      data: data,
    })
      .then(r => {
        console.log("OK")
        reset()
        setSubmitted(true)
        setTimeout(() => {
          setSubmitted(false)
        }, 5000)
      })
      .catch(r => {
        console.log(r.response.data.error)
      })
  }
  return (
    <Wrapper>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Title>
          Objednávkový formulář pro knížku<br></br>
          <BoldText> PRO DĚTI A PRO RADOST</BoldText>
        </Title>
        <Label htmlFor="name">Jméno a příjmení</Label> <br></br>
        <Input
          id="name"
          type="text"
          name="name"
          ref={register({
            required: true,
            minLength: 5,
          })}
        />
        <ErrorContainer>
          {errors.name && errors.name.type === "required" && (
            <ErrorText>Jméno musí být vyplněno.</ErrorText>
          )}
          {errors.name && errors.name.type === "minLength" && (
            <ErrorText>Zadané jméno je příliš krátké.</ErrorText>
          )}
        </ErrorContainer>
        <Label htmlFor="address">Adresa</Label> <br></br>
        <Input
          id="address"
          type="text"
          name="address"
          ref={register({
            required: true,
            minLength: 5,
          })}
        />
        <ErrorContainer>
          {errors.address && errors.address.type === "required" && (
            <ErrorText>Adresa musí být vyplněna.</ErrorText>
          )}
          {errors.address && errors.address.type === "minLength" && (
            <ErrorText>Zadaná adresa je příliš krátká.</ErrorText>
          )}
        </ErrorContainer>
        <Label htmlFor="phone">Telefon</Label> <br></br>
        <Input
          id="phone"
          type="text"
          name="phone"
          ref={register({
            required: true,
            pattern: /^(\+420)? ?[0-9]{3} ?[0-9]{3} ?[0-9]{3}$/,
          })}
        />
        <ErrorContainer>
          {errors.phone && errors.phone.type === "required" && (
            <ErrorText>Telefon musí být vyplněn.</ErrorText>
          )}
          {errors.phone && errors.phone.type === "pattern" && (
            <ErrorText>Telefon není ve správném tvaru.</ErrorText>
          )}
        </ErrorContainer>
        <Label htmlFor="email">E-mail</Label> <br></br>
        <Input
          id="email"
          type="text"
          name="email"
          ref={register({
            required: true,
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
        <ErrorContainer>
          {errors.email && errors.email.type === "required" && (
            <ErrorText>E-mail musí být vyplněn.</ErrorText>
          )}
          {errors.email && errors.email.type === "pattern" && (
            <ErrorText>E-mail není ve správném tvaru.</ErrorText>
          )}
        </ErrorContainer>
        <Label htmlFor="amount">Počet kusů</Label> <br></br>
        <Select
          name="amount"
          id="amount"
          ref={register}
          onChange={getDeliveryPrice}
        >
          <option value="1">1</option>
          <option value="2">2</option>
          <option value="3">3</option>
          <option value="4">4</option>
          <option value="5">5</option>
          <option value="6">6</option>
          <option value="7">7</option>
          <option value="8">8</option>
        </Select>
        <Text small>
          <NoteText>Při objenání 3 a více výtisků je doprava zdarma.</NoteText>
        </Text>
        <ErrorContainer></ErrorContainer>
        <Label htmlFor="delivery">Způsob doručení</Label> <br></br>
        <Select name="delivery" id="delivery" ref={register}>
          <option value="zasilkovna">{`Zásilkovna (${deliveryPrice})`}</option>
          <option value="ceska-posta">{`Česká pošta (${deliveryPrice})`}</option>
        </Select>
        <ErrorContainer></ErrorContainer>
        <Label htmlFor="note">Poznámka</Label> <br></br>
        <TextArea
          id="note"
          name="note"
          rows="6"
          cols="50"
          ref={register}
        ></TextArea>
        <ErrorContainer></ErrorContainer>
        <Text small>
          Po vytvoření objednávky vám budou do 24 hodin zaslány veškeré
          informace o dopravě a platbě na vámi uvedenou e-mailovou adresu.
          Knížky zasíláme po připsání částky na náš účet.
        </Text>
        <Text>
          Cena:<PriceText> 249 Kč </PriceText>
          <NoteText>+ poštovné (do Vánoc poštovné zdarma)</NoteText>
        </Text>
        <ButtonWrapper>
          <Button type="submit">Objednat</Button>
        </ButtonWrapper>
        {submitted ? (
          <SuccessText>
            Děkuji! Vaše objednávka byla úspěšně odeslána.
          </SuccessText>
        ) : null}
      </Form>
    </Wrapper>
  )
}

export default ContactForm
